<template>
    <div>
        <h1>Remakes</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import Options from "./remake_opt";
    import Results from "./utils/Results";

    export default {
        name: "remake",
        components: {
            Results,
            Options,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    startDate: {val: new Date(new Date().setDate(1)).toLocaleDateString(), qsField: '[dates][actual_ship_from]'},
                    endDate: {val: new Date().toLocaleDateString(), qsField: '[dates][actual_ship_to]'},
                    notShippedYet: {val: null, qsField: '[no_ship]'},
                    cdiErrorOnly: {val: null, qsField: '[cdi_error]'},
                    cdiLocationId: {val: '0', qsField: '[numbers][cdi_location_id]'},
                    salesPersonId: {val: '', qsField: '[subquery][tracker_sales_user]'},
                    type: {val: '0', qsField: '[numbers][remake]'},
                    specialTag: {val: '0', qsField: '[numbers][u.branch_id]'}
                },
                groupByFields: {},
                dataFields: {}
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        }, created() {
            this.$appStore.setTitle(['Remakes']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields,this.dataFields);
        },
    }
</script>

<style scoped>

</style>